<template>
  <forget-form @forgetPassword="forgetPassword($event)"></forget-form>
</template>
<script>
import forgetPassword from "../../library/auth/forget";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    "forget-form": forgetPassword,
  },
  data() {
    return {
      valid: false,
      url: process.env.VUE_APP_LOYALTY_API_URI,
      user: {},
      doctor: {},
    };
  },
  computed: {
    ...mapGetters({
      getAuth: "BFC/getAuth",
      getInfo: "BFC/getDoctorInfo",
    }),
  },
  methods: {
    ...mapActions({
      auth: "BFC/doctorAuth",
      setInfo: "BFC/setDoctorInfo",
      setAllCases: "BFC/setAllCases",
      setAuth: "BFC/setAuth",
    }),

    async forgetPassword(payload) {
      //console.log("login");
      console.log(payload);
      try {
        //let payload
        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/forgetPassword",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            console.log(response);
            // console.log(response.data);
            // if (response.data.statusCode == 0) {
            //   this.doctor = response.data;
            //   let payload = {
            //     _id: response.data.member._id,
            //     token: "",
            //   };
            //   let payloadAuth = {
            //     _id: response.data.member._id,
            //     authType: "Doctor",
            //     lastLogin: new Date(),
            //     token: "",
            //   };
            //   this.$cookies.set("Auth", payloadAuth);
            //   this.$cookies.set("Profile", response.data);
            //   await this.auth(payload);
            //   await this.setInfo(response.data);
            //   await this.setAuth(payloadAuth);
            //   //await this.listCases();
            //   // doctor info
            //   //console.log(this.getInfo);
            //   this.$router.push("/doctor/dashboard");
            // } else {
            //   // show error
            //   console.log("error response");
            //   alert("Incorrect email or password");
            // }
            if (response.data.statusCode == 0) {
              alert("Mail sent, please check your email");
            } else {
              alert("Error!, your email not found!");
            }
          });
      } catch (e) {
        //
        alert("Error!, something wrong please try again");
        console.log(e);
      }
    },
  },
};
</script>
