<template>
  <v-card class="mx-auto" min-width="30%" max-width="400px" style="margin-top: 10%">
    <v-img src="../../../../assets/logo.png" width="100%"></v-img>

    <v-card-title> Forgot Password? </v-card-title>

    <v-card-subtitle>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="user.username"
          label="Email"
          required
          :rules="emailRules"
          type="email"
          id="email"
        ></v-text-field>

        <v-btn color="success" class="mr-4" @click="forgetPassword"> Send Mail</v-btn>

        <v-btn color="error" class="mr-4" @click="reset"> Reset </v-btn>
      </v-form>
    </v-card-subtitle>
    <v-card-actions>
      <v-icon large color="blue">mdi-facebook</v-icon>
    </v-card-actions>
  </v-card>
</template>
<script>
//import { generateJwtToken } from "../../../../plugins/middleware";
//import axios from 'axios';
export default {
  name: "forgetPassword",
  data() {
    return {
      valid: false,
      user: {},
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /[^@]+@[^@]+.[a-zA-Z]{2,6}/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{7,15}$/.test(
            v
          ) ||
          "Password must have Numberic, Lowercase, Uppercase, special charactor ( !@#$%^&* ) and must between 6-12 charactors ",
      ],
    };
  },
  methods: {
    forgetPassword() {
      //console.log("login clicked");
      this.$emit("forgetPassword", this.user);
    },
    reset() {
      this.user = {
        username: "",
        // password: "",
      };
    },
  },
};
</script>
